import React from "react"

import Layout from "../Components/Layout";

/*
You can view my short form thoughts here, long form articles <Link className="cursor-pointer" to="/articles">here</Link>.
I prefer to host my content as it gives me freedom to do whatever I want and however I choose to do it, let's me own the content & respects privacy of users who view this blog.<sup>
          <a href="#ref-1">[1]</a>
        </sup>

<ol className="references text-xs list-decimal list-inside">
      <li id="ref-1">
        Hosting my own content ensures that i am in control of the privacy of the end users, not some corporation that i cannot hold accountable since i accepted it's multi-page terms of service.
      </li>
      <li>
        I host this content on an instance rented from hetzner and protected by Cloudflare.
    </li>
    </ol>
*/

export default function Home() {
  return <Layout>
    <div className="flex flex-col justify-items-stretch">
    <div className="content my-6 flex-grow">
      <h1 className="font-heading text-2xl text-center">Hello there, fellow human !</h1>
      <h2 className="font-heading text-center">I am Raviteja Guttula and this is my blog.</h2>
      <p className="font-body my-6 text-justify">
        I use this space to publish my ideas. This space is inspired from the ideas of <a target="_blank" rel="noreferrer" rel="noopener" href="http://snarkmarket.com/2010/4890" className="idea-link">Stock and Flow</a> from Robin Sloan, <a target="_blank" rel="noreferrer" rel="noopener" href="https://joelhooks.com/digital-garden" className="idea-link">Digital garden</a> from Joel Hook & <span className="idea-link">Captain's log</span> from Star Trek.
      </p>
    </div>
    
    </div>
  </Layout>
}



